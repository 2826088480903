/** @jsx jsx */
import { DUIButton, DUIInput, DUIText } from "@alch/ui";
import { useCallback, useState } from "react";
import { Box, jsx } from "theme-ui";

import { checkSSOEmail } from "../http/endpoints";
import looksLikeAnEmail from "../util/looksLikeAnEmail";
import DUIInputLabel from "./DUIInputLabel";
import Section from "./Section";
import { fullWidthSx } from "./styles";
import ValidatingForm from "./ValidatingForm";

const SSOLoginView = (props: {
  redirectUrl?: string;
  inviteToken?: string;
  initialEmail?: string;
}): React.ReactElement => {
  const [email, setEmail] = useState(props.initialEmail || "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = useCallback(() => {
    if (!loading) {
      setError("");
      setLoading(true);
      checkSSOEmail({
        email,
        redirectUrl: props.redirectUrl,
        inviteToken: props.inviteToken,
      })
        .then((res) => {
          window.location.href = res.redirectTo;
          return;
        })
        .catch((e: Error) => {
          setError(
            e.message ||
              "An unknown error occurred.  Please contact support@alchemy.com",
          );
          setLoading(false);
        });
    }
  }, [
    loading,
    setError,
    setLoading,
    email,
    props.redirectUrl,
    props.inviteToken,
  ]);

  return (
    <Box>
      <Section centered={true}>
        <DUIText size="2xl">Login With SSO</DUIText>
      </Section>
      <Section shaded={true}>
        <ValidatingForm onSubmitPreventDefault={handleLogin}>
          {error && (
            <DUIText mt={3} sx={{ color: "danger.core" }}>
              {error}
            </DUIText>
          )}
          <DUIInputLabel text="Email">
            <DUIInput
              type="email"
              placeholder="gavin@hooli.com"
              autoComplete="email"
              required={true}
              value={email}
              onValueChange={setEmail}
            />
          </DUIInputLabel>
          <DUIButton
            mt={5}
            sx={fullWidthSx}
            size="lg"
            type="submit"
            disabled={loading || !looksLikeAnEmail(email)}
          >
            Log in
          </DUIButton>
        </ValidatingForm>
      </Section>
    </Box>
  );
};

export default SSOLoginView;
