/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParams } from "use-query-params";

import MasterLayout from "../components/MasterLayout";
import SSOLoginView from "../components/SSOLoginView";

const SSOLoginPage = memo(function IndexPage(): ReactElement {
  const [{ redirectUrl, inviteToken, initialEmail }] = useQueryParams({
    redirectUrl: StringParam,
    inviteToken: StringParam,
    initialEmail: StringParam,
  });

  return (
    <MasterLayout title="Login with SSO">
      <SSOLoginView
        redirectUrl={redirectUrl ?? undefined}
        inviteToken={inviteToken ?? undefined}
        initialEmail={initialEmail ?? undefined}
      />
    </MasterLayout>
  );
});
export default SSOLoginPage;
